// 去除children为空的部门
export function formatList(list) {
    return list.map(item => {
        if (item.children && item.children.length) {
            formatList(item.children)
        } else {
            item.children = null
        }
        return item
    })
}

// 查找所有父节点一
export function findAllParent(node, tree, parentNodes = [], index = 0) {
    if (!node || node.pid === 0) {
        return
    }
    findParent(node, parentNodes, tree)
    let parentNode = parentNodes[index]
    findAllParent(parentNode, tree, parentNodes, ++index)
    return parentNodes
}

function findParent(node, parentNodes, tree) {
    for (let i = 0; i < tree.length; i++) {
        let item = tree[i];
        if (item.id === node.pid) {
            parentNodes.push(item);
            return
        }
        if (item.children && item.children.length > 0) {
            findParent(node, parentNodes, item.children)
        }
    }
}

// 查找所有父节点二
export function findAllparentNode(node, tree) {
    const parentNodes = [], index = 0;

    function findParent(node, tree, index = 0) {
        let nextIndex = index + 1
        for (let i = 0; i < tree.length; i++) {
            if (tree[i].id === node.id) {
                parentNodes[index] = tree[i].id
                return
            }
            if (tree[i].children && tree[i].children.length) {
                findParent(node, tree[i].children, nextIndex)
            }
            if (parentNodes[nextIndex]) {
                parentNodes[index] = tree[i].id
                return
            }
        }
    }

    findParent(node, tree)
    return parentNodes
}


// 序列化返回的echart数据
export function formatEchartArr (arr) {
    const xData = [], yData = [];
    arr.forEach(item => {
        xData.push(item[0])
        yData.push(item[1])
    })
    return [xData, yData]
}

//获取url的search参数
export function getUrlParam(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}