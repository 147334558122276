import http from './http';

// 获取openId
export function fetchOpenId(data) {
    return http.fetch(`/wxopenid`,data,{method:'post'})
}

// 绑定微信公众号
export function bindAccount(data) {
    return http.fetch(`/users`   ,data,{method:'post'})
}

// 绑定微信公众号
export function unBindAccount(data) {
    return http.fetch(`/users/unbind`   ,data,{method:'post'})
}