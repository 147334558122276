
import MobileLayout from '../layouts/MobileLayout';

//---------------------------移动端-----------------------------
import wx from './routers/wx'            //微信公众号

const routerConfig = [
    {
        path: '/mobile',
        component: MobileLayout,
        children:[
            ...wx,
        ]
    }
];

export default routerConfig;
