import store from 'store'

const OPEN_ID = 'OPEN_ID';

export const openIdStorage = {
    saveOpenId(openid) {
        store.set(OPEN_ID, openid)
    },
    getOpenId() {
        return store.get(OPEN_ID) || ''
    },
    removeOpenId() {
        store.remove(OPEN_ID)
    }
}