import axios from 'axios';
import { message } from 'antd';

//设置请求的配置信息
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '/';
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://weixin.gentory.cn/api';
}
axios.defaults.responseType = 'json';
axios.defaults.withCredentials= true;

//设置取消请求的token
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

//拦截请求 发送请求前的操作
axios.interceptors.request.use(config => {
        // 获取用户登录token
        config.cancelToken = source.token ;
        config.headers['Content-Type'] = 'application/json;charset=UTF-8';
        config.headers['Cache-Control'] = 'no-cache';
        config.params = {
            _t: Date.parse(new Date())/1000,
            ...config.params
        }
        return config;
    },
    error =>{
        return Promise.error(error)
    }
);

//拦截响应，统一返回错误信息
axios.interceptors.response.use( (response)=> {
    if (response.status >= 200 && response.status < 300 ) {
        return response.data
    }else {
        return Promise.reject(response.data);
    }
},  (err)=> {
    if (err && err.response) {
        err.message = `连接出错(${err.response.status})!`;
    }else{
        err.message = '连接服务器失败!'
    }
    return Promise.reject(err);
});

//请求方式
export default {

    fetch(url, data,{method}) {
        return new Promise((resolve,reject)=>{
            axios({
                method: method,
                url,
                data: JSON.stringify(data),
            }).then(res => {
                resolve(res)
            }).catch(e=>{
                message.error(e.message);
            })
        })
    },
}
