import React, {Component} from 'react'

import './index.less'

class MobileLayout extends Component {

    preventEvent=(event)=>{
        event.preventDefault()
    }

    componentDidMount() {
        document.addEventListener('gesturestart', this.preventEvent)
    }

    componentWillUnmount() {
        document.removeEventListener('gesturestart',this.preventEvent)
    }

    render() {
        return (
            <div className='mobile-wrap'>
                {this.props.children}
            </div>
        )
    }
}

export default MobileLayout;

