import 'core-js'
import 'mutation-observer'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import router from './router';

import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment'
import 'moment/locale/zh-cn'

import './index.less';
moment.locale('zh-cn');

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        {router()}
    </ConfigProvider>,
    document.getElementById('root')
);
