import React, {Component} from 'react';
import {
    Input,
    Form,
    Button,
    Select,
    Modal,
    message
} from 'antd';
import './bindAccount.less'
import logoImg from '../../../assets/imgs/mobile/logo.jpg'
import {getUrlParam} from '../../../utils/utils'
import {openIdStorage} from '../../../utils/storage'
import {fetchOpenId,bindAccount,unBindAccount} from "../../../api";

@Form.create()
export default class BindAccount extends Component {
    state = {
        hasBind:false,
        bindStatus:false,
        isLoading:true,
        userInfo:{

        }
    }

    getOpenId=()=>{
        this.openId=openIdStorage.getOpenId()
        if(this.openId){
            console.log('发送ajax')
        }else {
            this.getCode()
        }
    }

    getCode () { // 非静默授权，第一次有弹框
        const code = getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
        const local = window.location.href
        console.log(local)
        if (code == null || code === '') {
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa8bc1297461fb0f4&redirect_uri=' + encodeURIComponent('https://weixin.gentory.cn/#/mobile/wx/bindAccount') + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
        } else {
            this._fetchOpenId(code) //把code传给后台获取用户信息
        }
    }

    _fetchOpenId =(code)=> { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
        fetchOpenId({code}).then(res=>{
            const {code,datas}=res;
            this.setState({
                isLoading:false
            });
            if(code===403){
                window.location.href='/#/mobile/wx/bindAccount'
            }else {
                const {wxOpenid,...args}=datas;
                if(code===9013){
                    this.setState({
                        hasBind:true,
                        userInfo:args
                    })
                }
                this.openId=wxOpenid;
            }
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                bindAccount({...values,openid:this.openId}).then(res=>{
                    const {code}=res;
                    if(code===9003){
                        this.setState({
                            bindStatus:true
                        })
                    }else {
                        message.error(res.message)
                    }
                })
            }
        });
    };

    okHandle=()=>{
        window.location.href='/#/mobile/wx/bindAccount'
    }

    unBindHandle=()=>{
        Modal.confirm({
            title: '确定要解绑吗？',
            width:'90%',
            centered: true,
            cancelText: '取消',
            okText: '确定',
            onOk: () => {
                const {userName, userComp, wxOpenid} =this.state.userInfo
                console.log(`username is:${userName},usercomp is:${userComp}, wxOpenid is:${wxOpenid}`)
                unBindAccount({userName:userName, userComp:userComp, wxOpenid:wxOpenid}).then(res=>{
                    const {code}=res;
                    if(code===9005){
                        this.setState({
                            hasBind:false
                        })
                        message.success(res.message)
                    }else {
                        message.error(res.message)
                    }
                    
                })
            }
        })
    }

    componentDidMount() {
        this.getOpenId()
    }

    render() {
        const {hasBind,bindStatus,isLoading,userInfo}=this.state;
        const {form:{getFieldDecorator}} = this.props;

        return (
            <>
            {
                isLoading?
                    null:
                    <div className='form-body'>
                        <div className='logo-box'>
                            <img src={logoImg} alt=""/>
                        </div>
                        <h2>{hasBind?'芦家峪瓦斯发电厂':bindStatus?'账号绑定成功':'智慧电厂账号绑定微信公众号'}</h2>
                        {
                            hasBind?
                                <Form className="login-form">
                                    <Form.Item>
                                        <p className={'description-box'}>用户账号：{userInfo.userName}</p>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={this.unBindHandle} size='large' type="primary" block className="login-form-button">
                                            解 绑
                                        </Button>
                                    </Form.Item>
                                </Form>:
                                bindStatus?
                                    <Form className="login-form">
                                        <Form.Item>
                                            <p className={'description-box'}>此微信可以接收来自智慧电厂的消息通知</p>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button onClick={this.okHandle} size='large' type="primary" block className="login-form-button">
                                                好 的
                                            </Button>
                                        </Form.Item>
                                    </Form>:
                                    <Form onSubmit={this.handleSubmit} className="login-form">
                                        <Form.Item>
                                            {getFieldDecorator('dianzhanNO', {
                                                validateFirst: true,
                                                rules: [
                                                    {required: true, message: '请选择电厂'},
                                                ],
                                            })(
                                                <Select  size={'large'} placeholder="选择所在电厂">
                                                    <Select.Option value={'01'}>芦家峪瓦斯发电厂</Select.Option>
                                                </Select>,
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('account', {
                                                validateFirst: true,
                                                rules: [
                                                    {required: true, message: '请输入用户账号'},
                                                ],
                                            })(
                                                <Input
                                                    size={'large'}
                                                    placeholder="用户账号"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('password', {
                                                validateFirst: true,
                                                rules: [
                                                    {required: true, message: '请输入密码'},
                                                ],
                                            })(
                                                <Input
                                                    size={'large'}
                                                    type="password"
                                                    placeholder="密码"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            <Button size='large' type="primary" htmlType="submit" block className="login-form-button">
                                                提 交
                                            </Button>
                                        </Form.Item>
                                    </Form>
                        }
                    </div>
            }
            </>
        );
    }
}
